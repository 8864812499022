.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.character {
    display: flex;
}

.weapon {
    display: flex;
}

.item {
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 6px;
}

.img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
}

.level {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
    border-bottom-right-radius: 4px;
    background-color: gainsboro;
    display: flex;
    align-items: center;
    justify-content: center;
}