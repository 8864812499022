.first {
    margin-bottom: 16px;
}

.background {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    pointer-events: none;
    background-image: url('../../../public/yanfei/yanfei.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.2;
}

.title {
    margin-bottom: 24px;
    color: #555555;
    font-size: 18px;
    font-weight: 700;
}

.subtitle {
    margin-bottom: 16px;
    color: #555555;
    font-size: 14px;
    font-weight: 600;
}

.select {
    margin-bottom: 8px;
}

.line {
    margin-bottom: 4px;
    color: #555555;
    font-size: 14px;
}